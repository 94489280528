
import imageheader from "./../assets/images/image-header.png";
import headermobile from "./../assets/images/header-mobile.svg";
import {deleteUserFromLocalStorage} from "./../utils";

const Header = ({ hidePhoto }) => {

  return (
    <header className="header">
       {
        !hidePhoto &&
        (
          <a href="#" onClick={()=>{
            deleteUserFromLocalStorage(()=>{
              if(window.confirm("¿Estás seguro de cerrar sesión?")){
                window.location.href = "/";
              }
            
            })
    
          }} className="btn btn-primary btn-icon" >Cerrar sesión</a>
        )

       }     


      <img className="header__bg" src={headermobile} />
      {
        !hidePhoto &&
        (
          <img className="header__image" src={imageheader} />
        )
      }

    </header>
  )
}

export default Header;