
import React, { useCallback, useEffect, useState, useRef } from 'react'
import MainLayout from "./MainLayout";
import iconcamera from "./../assets/images/icon-camera.svg";
import example from "./../assets/images/example-image.jpg";
import { useDropzone } from 'react-dropzone'
import { app, db } from "./../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { fromImage } from 'imtool';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { Link } from "react-router-dom";
import Switch from "react-switch";
import 'photoswipe/style.css';
import {
    doc,
    setDoc,
    Timestamp,
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
    limit
} from "firebase/firestore";
import { uuidv4, getUserFromLocalStorage, setUserToLocalStorage } from "./../utils";

const Main = () => {

    var userLogin = getUserFromLocalStorage();

    const [fotos, setFotos] = useState([]);
    const [files, setFiles] = useState([]);
    const [only, setOnly] = useState(userLogin.onlyMyData);
    const auth = getAuth(app);
    const [user] = useAuthState(auth);
    const unsubFromMessagesRef = useRef();

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles);
        setFiles(acceptedFiles);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/*': ['.jpeg', '.png']
        }
    })

    useEffect(() => {
        if (files.length > 0) {
            files.forEach(file => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    let base64File = reader.result;

                    const img = new Image();
                    img.src = base64File;
                    img.onload = () => {
                        fromImage(base64File)
                            .then(tool => tool.thumbnail(800, false).toDataURL())
                            .then(blob => {
                                const docData = {
                                    id: uuidv4(),
                                    userId: userLogin.id,
                                    userName: userLogin.nombre,
                                    activa: true,
                                    user: "Andrés",
                                    image: blob,
                                    width: img.width,
                                    height: img.height,
                                    fecha: Timestamp.fromDate(new Date()),
                                };
                                setDoc(doc(db, "fotos", uuidv4()), docData);
                            })
                            .catch(e => {
                                console.error(e);
                            });



                        //alert(img.width + " " + img.height);
                    }




                };

            });
            setFiles([]);
        }

    }, [files]);


    useEffect(() => {
      

        if (user) {
            const q =
                !only ?
                    query(collection(db, "fotos"), where("activa", "==", true),limit(50),orderBy("fecha", "desc"))
                    :
                    query(collection(db, "fotos"), where("activa", "==", true), where("userId", "==", userLogin.id),limit(50),orderBy("fecha", "desc"));

            const unsubFromMessages = onSnapshot(q, (snapshot) => {
                //setProfiles(snapshot.docs.map((doc)=>({...doc.data(), id: doc.id})))
                var arr = [];
                snapshot.docs.map((doc) => {
                    arr.push(doc.data());
                });
                setFotos(arr);
            });
            unsubFromMessagesRef.current = unsubFromMessages;
        }

        return () => {
            unsubFromMessagesRef.current && unsubFromMessagesRef.current();
        };

    }, [user, only]);

    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery1',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);



    return (
        <MainLayout>
            <div className="buttons">
                <a href="#" className="btn btn-primary btn-icon"><img src={iconcamera} /> Subir fotos</a>
                <div className="buttons__group">
                    <Link className="btn btn-secondary btn-mini" to={'/signature-book'}>Libro de firmas</Link>

                </div>
            </div>
            <div className="content">
                <div className="content__int">

                    <label>
                        <span>Sólo mis fotos</span>
                        <Switch onChange={() => {
                            setOnly(!only);
                            userLogin.onlyMyData = !only;
                            setUserToLocalStorage(userLogin);
                        }} checked={only} />
                    </label>

                    <h1 className="title">Albúm de fotos</h1>

                    <div className="pswp-gallery" id={'gallery1'}>
                        {fotos.map((foto, index) => (
                            <a
                                className="list__item"
                                href={foto.image}
                                data-pswp-width={foto.width}
                                data-pswp-height={foto.height}
                                key={foto.id}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img style={{ height: '150px', width: '150px' }} src={foto.image} alt="" />
                            </a>
                        ))}
                    </div>



                    <ul className="list">

                        {
                            fotos.map(foto => (
                                <li key={foto.id} className="list__item"><img className="" src={foto.image} /><span className="user">@lauhered</span></li>
                            ))
                        }
                        {/* <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li> */}
                    </ul>
                    <div className="buttons">
                        <a href="#" className="btn btn-secondary btn-icon">Cargar más</a>
                        <br />
                        <a href="#" className="btn btn-primary btn-icon"><img src={iconcamera} /> Subir fotos</a>
                        {/* <input accept="image/*" id="icon-button-file" type="file" capture="environment" /> */}


                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                            }
                        </div>
                    </div>

                </div>
            </div>

        </MainLayout>


    )
}

export default Main