
import { Route, Navigate,useLocation } from "react-router-dom";
import {getUserFromLocalStorage} from "./../utils";

const RouteGuard = ({children }) => {
    const location = useLocation();
    const user=getUserFromLocalStorage();  
   
    if (!user) {
      return <Navigate to={`/login?returnUrl=${location.pathname}`} replace />;
    } 
  
    return children;
  };

  export default RouteGuard;